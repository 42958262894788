import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="テキストフィールド - コンポーネント" mdxType="SEO" />
    <PageTitle title="テキストフィールド" enTitle="TextField" mdxType="PageTitle" />
    <p>{`ユーザーが自発的に文字を入力できるコンポーネントです。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ameba-spindle.web.app/?path=/docs/form-textfield--large"
        }}>{`Storybook`}</a></li>
    </ul>
    <h2 {...{
      "id": "原則",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%8E%9F%E5%89%87",
        "aria-label": "原則 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`原則`}</h2>
    <ol>
      <li parentName="ol">{`ユーザーが、自身で入力できる箇所であることがひと目で分かるデザインであること。`}</li>
      <li parentName="ol">{`ユーザーが、そこに何をどのように入力すべきかが明瞭に記載されていること。`}</li>
      <li parentName="ol">{`ユーザーの誤った入力に対して過不足無く指摘すること。`}</li>
      <li parentName="ol">{`ユーザーの入力状況を、明瞭に伝えること。`}</li>
    </ol>
    <h2 {...{
      "id": "解剖",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E8%A7%A3%E5%89%96",
        "aria-label": "解剖 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`解剖`}</h2>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6b375771c8c5327faf087697a4438bdd/29007/textfield-anatomy.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6b375771c8c5327faf087697a4438bdd/2aaaf/textfield-anatomy.webp 160w", "/static/6b375771c8c5327faf087697a4438bdd/85e47/textfield-anatomy.webp 320w", "/static/6b375771c8c5327faf087697a4438bdd/75198/textfield-anatomy.webp 640w", "/static/6b375771c8c5327faf087697a4438bdd/691bc/textfield-anatomy.webp 960w", "/static/6b375771c8c5327faf087697a4438bdd/223e5/textfield-anatomy.webp 1280w", "/static/6b375771c8c5327faf087697a4438bdd/2158a/textfield-anatomy.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6b375771c8c5327faf087697a4438bdd/69538/textfield-anatomy.png 160w", "/static/6b375771c8c5327faf087697a4438bdd/72799/textfield-anatomy.png 320w", "/static/6b375771c8c5327faf087697a4438bdd/6af66/textfield-anatomy.png 640w", "/static/6b375771c8c5327faf087697a4438bdd/d9199/textfield-anatomy.png 960w", "/static/6b375771c8c5327faf087697a4438bdd/21b4d/textfield-anatomy.png 1280w", "/static/6b375771c8c5327faf087697a4438bdd/29007/textfield-anatomy.png 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/6b375771c8c5327faf087697a4438bdd/6af66/textfield-anatomy.png",
              "alt": "TextFieldとラベルやパスワード表示などのUIのレイアウト例",
              "title": "TextFieldとラベルやパスワード表示などのUIのレイアウト例",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <details>
      <summary>1. ラベル</summary>
      <p>{`ユーザーが何を入力すればよいのかを、簡潔に明示したエリアです。`}</p>
    </details>
    <details>
      <summary>2. 必須ラベル</summary>
      <p>{`入力が必須な項目に対して必要なラベルです。`}</p>
    </details>
    <details>
      <summary>3. 入力エリア</summary>
      <p>{`ユーザーがテキストを入力するエリアです。`}</p>
    </details>
    <details>
      <summary>4. プレースホルダー</summary>
      <p>{`ユーザーが入力を開始すると消える、デフォルトの仮入力状態のテキストです。ユーザーの入力フォーマットを暗示するためのヒントとして使用します。`}</p>
      <p>{`プレースホルダーテキストを見出しや入力規則を書くことは推奨されません。`}</p>
    </details>
    <details>
      <summary>5. 入力規則</summary>
      <p>{`入力内容に制限がある場合は、ここに記載します。`}</p>
    </details>
    <details>
      <summary>6. 文字カウンター</summary>
      <p>{`文字数に制限がある場合は、入力可能な残り文字数をカウントします。`}</p>
    </details>
    <details>
      <summary>7. 注釈</summary>
      <p>{`入力にあたっての注意事項など、クリティカルなエラーには繋がらないが、ユーザーに周知したい内容を記載します。`}</p>
    </details>
    <details>
      <summary>8. トレーリングアイコン</summary>
      <p>{`入力エリアに作用する動作や、入力エリアに映し出されている情報を、簡易的に表すことのできるアイコンです。`}</p>
    </details>
    <details>
      <summary>9. エラーテキスト</summary>
      <p>{`ユーザーにエラーの状態を伝えるテキストです。ユーザーがエラーを見逃さないようにしてください。`}</p>
    </details>
    <details>
      <summary>10. 表示チェックボックス</summary>
      <p>{`主にパスワードの入力フォームで使用するチェックボックスです。チェックを入れるとパスワードが表示されるようになります。`}</p>
    </details>
    <h3 {...{
      "id": "基本",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%9F%BA%E6%9C%AC",
        "aria-label": "基本 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`基本`}</h3>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6ea839bf32c337bff316abb59d90768a/29007/textfield-spec-basic1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "76.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6ea839bf32c337bff316abb59d90768a/2aaaf/textfield-spec-basic1.webp 160w", "/static/6ea839bf32c337bff316abb59d90768a/85e47/textfield-spec-basic1.webp 320w", "/static/6ea839bf32c337bff316abb59d90768a/75198/textfield-spec-basic1.webp 640w", "/static/6ea839bf32c337bff316abb59d90768a/691bc/textfield-spec-basic1.webp 960w", "/static/6ea839bf32c337bff316abb59d90768a/223e5/textfield-spec-basic1.webp 1280w", "/static/6ea839bf32c337bff316abb59d90768a/2158a/textfield-spec-basic1.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6ea839bf32c337bff316abb59d90768a/69538/textfield-spec-basic1.png 160w", "/static/6ea839bf32c337bff316abb59d90768a/72799/textfield-spec-basic1.png 320w", "/static/6ea839bf32c337bff316abb59d90768a/6af66/textfield-spec-basic1.png 640w", "/static/6ea839bf32c337bff316abb59d90768a/d9199/textfield-spec-basic1.png 960w", "/static/6ea839bf32c337bff316abb59d90768a/21b4d/textfield-spec-basic1.png 1280w", "/static/6ea839bf32c337bff316abb59d90768a/29007/textfield-spec-basic1.png 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/6ea839bf32c337bff316abb59d90768a/6af66/textfield-spec-basic1.png",
              "alt": "基本的なパターンにおけるデフォルト表示、選択中、エラー時のスタイル定義",
              "title": "基本的なパターンにおけるデフォルト表示、選択中、エラー時のスタイル定義",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1f6cf6e221136fa090e75d0d3cef3795/29007/textfield-spec-basic2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1f6cf6e221136fa090e75d0d3cef3795/2aaaf/textfield-spec-basic2.webp 160w", "/static/1f6cf6e221136fa090e75d0d3cef3795/85e47/textfield-spec-basic2.webp 320w", "/static/1f6cf6e221136fa090e75d0d3cef3795/75198/textfield-spec-basic2.webp 640w", "/static/1f6cf6e221136fa090e75d0d3cef3795/691bc/textfield-spec-basic2.webp 960w", "/static/1f6cf6e221136fa090e75d0d3cef3795/223e5/textfield-spec-basic2.webp 1280w", "/static/1f6cf6e221136fa090e75d0d3cef3795/2158a/textfield-spec-basic2.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1f6cf6e221136fa090e75d0d3cef3795/69538/textfield-spec-basic2.png 160w", "/static/1f6cf6e221136fa090e75d0d3cef3795/72799/textfield-spec-basic2.png 320w", "/static/1f6cf6e221136fa090e75d0d3cef3795/6af66/textfield-spec-basic2.png 640w", "/static/1f6cf6e221136fa090e75d0d3cef3795/d9199/textfield-spec-basic2.png 960w", "/static/1f6cf6e221136fa090e75d0d3cef3795/21b4d/textfield-spec-basic2.png 1280w", "/static/1f6cf6e221136fa090e75d0d3cef3795/29007/textfield-spec-basic2.png 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1f6cf6e221136fa090e75d0d3cef3795/6af66/textfield-spec-basic2.png",
              "alt": "入力内容の非表示・表示切り替えのUIのスタイル定義",
              "title": "入力内容の非表示・表示切り替えのUIのスタイル定義",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/48457dbe2ad81b6cc8bb4c526b303090/29007/textfield-spec-basic3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/48457dbe2ad81b6cc8bb4c526b303090/2aaaf/textfield-spec-basic3.webp 160w", "/static/48457dbe2ad81b6cc8bb4c526b303090/85e47/textfield-spec-basic3.webp 320w", "/static/48457dbe2ad81b6cc8bb4c526b303090/75198/textfield-spec-basic3.webp 640w", "/static/48457dbe2ad81b6cc8bb4c526b303090/691bc/textfield-spec-basic3.webp 960w", "/static/48457dbe2ad81b6cc8bb4c526b303090/223e5/textfield-spec-basic3.webp 1280w", "/static/48457dbe2ad81b6cc8bb4c526b303090/2158a/textfield-spec-basic3.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/48457dbe2ad81b6cc8bb4c526b303090/69538/textfield-spec-basic3.png 160w", "/static/48457dbe2ad81b6cc8bb4c526b303090/72799/textfield-spec-basic3.png 320w", "/static/48457dbe2ad81b6cc8bb4c526b303090/6af66/textfield-spec-basic3.png 640w", "/static/48457dbe2ad81b6cc8bb4c526b303090/d9199/textfield-spec-basic3.png 960w", "/static/48457dbe2ad81b6cc8bb4c526b303090/21b4d/textfield-spec-basic3.png 1280w", "/static/48457dbe2ad81b6cc8bb4c526b303090/29007/textfield-spec-basic3.png 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/48457dbe2ad81b6cc8bb4c526b303090/6af66/textfield-spec-basic3.png",
              "alt": "TextFieldの右端にクリアボタンがあるときのスタイル定義",
              "title": "TextFieldの右端にクリアボタンがあるときのスタイル定義",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0186fcdad900f6923622566060a04a85/29007/textfield-spec-basic4.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0186fcdad900f6923622566060a04a85/2aaaf/textfield-spec-basic4.webp 160w", "/static/0186fcdad900f6923622566060a04a85/85e47/textfield-spec-basic4.webp 320w", "/static/0186fcdad900f6923622566060a04a85/75198/textfield-spec-basic4.webp 640w", "/static/0186fcdad900f6923622566060a04a85/691bc/textfield-spec-basic4.webp 960w", "/static/0186fcdad900f6923622566060a04a85/223e5/textfield-spec-basic4.webp 1280w", "/static/0186fcdad900f6923622566060a04a85/2158a/textfield-spec-basic4.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0186fcdad900f6923622566060a04a85/69538/textfield-spec-basic4.png 160w", "/static/0186fcdad900f6923622566060a04a85/72799/textfield-spec-basic4.png 320w", "/static/0186fcdad900f6923622566060a04a85/6af66/textfield-spec-basic4.png 640w", "/static/0186fcdad900f6923622566060a04a85/d9199/textfield-spec-basic4.png 960w", "/static/0186fcdad900f6923622566060a04a85/21b4d/textfield-spec-basic4.png 1280w", "/static/0186fcdad900f6923622566060a04a85/29007/textfield-spec-basic4.png 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/0186fcdad900f6923622566060a04a85/6af66/textfield-spec-basic4.png",
              "alt": "TextFieldの下に入力内容を保存するCheckboxがあるときのスタイル定義",
              "title": "TextFieldの下に入力内容を保存するCheckboxがあるときのスタイル定義",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h3 {...{
      "id": "オートコンプリート",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%AA%E3%83%BC%E3%83%88%E3%82%B3%E3%83%B3%E3%83%97%E3%83%AA%E3%83%BC%E3%83%88",
        "aria-label": "オートコンプリート permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`オートコンプリート`}</h3>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/53f02583ccf721cf68ae6792e467aece/29007/textfield-spec-autocomplete.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/53f02583ccf721cf68ae6792e467aece/2aaaf/textfield-spec-autocomplete.webp 160w", "/static/53f02583ccf721cf68ae6792e467aece/85e47/textfield-spec-autocomplete.webp 320w", "/static/53f02583ccf721cf68ae6792e467aece/75198/textfield-spec-autocomplete.webp 640w", "/static/53f02583ccf721cf68ae6792e467aece/691bc/textfield-spec-autocomplete.webp 960w", "/static/53f02583ccf721cf68ae6792e467aece/223e5/textfield-spec-autocomplete.webp 1280w", "/static/53f02583ccf721cf68ae6792e467aece/2158a/textfield-spec-autocomplete.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/53f02583ccf721cf68ae6792e467aece/69538/textfield-spec-autocomplete.png 160w", "/static/53f02583ccf721cf68ae6792e467aece/72799/textfield-spec-autocomplete.png 320w", "/static/53f02583ccf721cf68ae6792e467aece/6af66/textfield-spec-autocomplete.png 640w", "/static/53f02583ccf721cf68ae6792e467aece/d9199/textfield-spec-autocomplete.png 960w", "/static/53f02583ccf721cf68ae6792e467aece/21b4d/textfield-spec-autocomplete.png 1280w", "/static/53f02583ccf721cf68ae6792e467aece/29007/textfield-spec-autocomplete.png 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/53f02583ccf721cf68ae6792e467aece/6af66/textfield-spec-autocomplete.png",
              "alt": "TextFieldにAuto Completeが有効なときのスタイル定義",
              "title": "TextFieldにAuto Completeが有効なときのスタイル定義",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h3 {...{
      "id": "テキストエリア",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88%E3%82%A8%E3%83%AA%E3%82%A2",
        "aria-label": "テキストエリア permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`テキストエリア`}</h3>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a7a987d1e93707b0b6e8269964e5dfde/29007/textfield-spec-textarea.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a7a987d1e93707b0b6e8269964e5dfde/2aaaf/textfield-spec-textarea.webp 160w", "/static/a7a987d1e93707b0b6e8269964e5dfde/85e47/textfield-spec-textarea.webp 320w", "/static/a7a987d1e93707b0b6e8269964e5dfde/75198/textfield-spec-textarea.webp 640w", "/static/a7a987d1e93707b0b6e8269964e5dfde/691bc/textfield-spec-textarea.webp 960w", "/static/a7a987d1e93707b0b6e8269964e5dfde/223e5/textfield-spec-textarea.webp 1280w", "/static/a7a987d1e93707b0b6e8269964e5dfde/2158a/textfield-spec-textarea.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/a7a987d1e93707b0b6e8269964e5dfde/69538/textfield-spec-textarea.png 160w", "/static/a7a987d1e93707b0b6e8269964e5dfde/72799/textfield-spec-textarea.png 320w", "/static/a7a987d1e93707b0b6e8269964e5dfde/6af66/textfield-spec-textarea.png 640w", "/static/a7a987d1e93707b0b6e8269964e5dfde/d9199/textfield-spec-textarea.png 960w", "/static/a7a987d1e93707b0b6e8269964e5dfde/21b4d/textfield-spec-textarea.png 1280w", "/static/a7a987d1e93707b0b6e8269964e5dfde/29007/textfield-spec-textarea.png 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/a7a987d1e93707b0b6e8269964e5dfde/6af66/textfield-spec-textarea.png",
              "alt": "複数行入力できるテキストエリアのスタイル定義",
              "title": "複数行入力できるテキストエリアのスタイル定義",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h2 {...{
      "id": "パターン",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%91%E3%82%BF%E3%83%BC%E3%83%B3",
        "aria-label": "パターン permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`パターン`}</h2>
    <p>{`入力される内容やValidationのパターンに対応するため、TextFieldにはいくつかの型が用意されています。`}</p>
    <h3 {...{
      "id": "基本-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%9F%BA%E6%9C%AC-1",
        "aria-label": "基本 1 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`基本`}</h3>
    <p>{`AmebaにおけるTextFieldの基準となる型です。`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/86296212cf6e5d0761d2e3d2e4931638/29007/textfield-pattern-basic1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "51.25000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/86296212cf6e5d0761d2e3d2e4931638/2aaaf/textfield-pattern-basic1.webp 160w", "/static/86296212cf6e5d0761d2e3d2e4931638/85e47/textfield-pattern-basic1.webp 320w", "/static/86296212cf6e5d0761d2e3d2e4931638/75198/textfield-pattern-basic1.webp 640w", "/static/86296212cf6e5d0761d2e3d2e4931638/691bc/textfield-pattern-basic1.webp 960w", "/static/86296212cf6e5d0761d2e3d2e4931638/223e5/textfield-pattern-basic1.webp 1280w", "/static/86296212cf6e5d0761d2e3d2e4931638/2158a/textfield-pattern-basic1.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/86296212cf6e5d0761d2e3d2e4931638/69538/textfield-pattern-basic1.png 160w", "/static/86296212cf6e5d0761d2e3d2e4931638/72799/textfield-pattern-basic1.png 320w", "/static/86296212cf6e5d0761d2e3d2e4931638/6af66/textfield-pattern-basic1.png 640w", "/static/86296212cf6e5d0761d2e3d2e4931638/d9199/textfield-pattern-basic1.png 960w", "/static/86296212cf6e5d0761d2e3d2e4931638/21b4d/textfield-pattern-basic1.png 1280w", "/static/86296212cf6e5d0761d2e3d2e4931638/29007/textfield-pattern-basic1.png 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/86296212cf6e5d0761d2e3d2e4931638/6af66/textfield-pattern-basic1.png",
              "alt": "TextFieldの基本的なパターン一覧",
              "title": "TextFieldの基本的なパターン一覧",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6751d11f7154401cd85535c43213d6ec/29007/textfield-pattern-basic2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.62500000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6751d11f7154401cd85535c43213d6ec/2aaaf/textfield-pattern-basic2.webp 160w", "/static/6751d11f7154401cd85535c43213d6ec/85e47/textfield-pattern-basic2.webp 320w", "/static/6751d11f7154401cd85535c43213d6ec/75198/textfield-pattern-basic2.webp 640w", "/static/6751d11f7154401cd85535c43213d6ec/691bc/textfield-pattern-basic2.webp 960w", "/static/6751d11f7154401cd85535c43213d6ec/223e5/textfield-pattern-basic2.webp 1280w", "/static/6751d11f7154401cd85535c43213d6ec/2158a/textfield-pattern-basic2.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6751d11f7154401cd85535c43213d6ec/69538/textfield-pattern-basic2.png 160w", "/static/6751d11f7154401cd85535c43213d6ec/72799/textfield-pattern-basic2.png 320w", "/static/6751d11f7154401cd85535c43213d6ec/6af66/textfield-pattern-basic2.png 640w", "/static/6751d11f7154401cd85535c43213d6ec/d9199/textfield-pattern-basic2.png 960w", "/static/6751d11f7154401cd85535c43213d6ec/21b4d/textfield-pattern-basic2.png 1280w", "/static/6751d11f7154401cd85535c43213d6ec/29007/textfield-pattern-basic2.png 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/6751d11f7154401cd85535c43213d6ec/6af66/textfield-pattern-basic2.png",
              "alt": "TextFieldに脚注やクリアボタン、Checkboxがあるパターン",
              "title": "TextFieldに脚注やクリアボタン、Checkboxがあるパターン",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h3 {...{
      "id": "必須",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%BF%85%E9%A0%88",
        "aria-label": "必須 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`必須`}</h3>
    <p>{`ユーザーの入力が必須な時に使用するTextFIeldです。`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7eddbebb880850ace7296028f8f48b09/29007/textfield-pattern-require1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7eddbebb880850ace7296028f8f48b09/2aaaf/textfield-pattern-require1.webp 160w", "/static/7eddbebb880850ace7296028f8f48b09/85e47/textfield-pattern-require1.webp 320w", "/static/7eddbebb880850ace7296028f8f48b09/75198/textfield-pattern-require1.webp 640w", "/static/7eddbebb880850ace7296028f8f48b09/691bc/textfield-pattern-require1.webp 960w", "/static/7eddbebb880850ace7296028f8f48b09/223e5/textfield-pattern-require1.webp 1280w", "/static/7eddbebb880850ace7296028f8f48b09/2158a/textfield-pattern-require1.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7eddbebb880850ace7296028f8f48b09/69538/textfield-pattern-require1.png 160w", "/static/7eddbebb880850ace7296028f8f48b09/72799/textfield-pattern-require1.png 320w", "/static/7eddbebb880850ace7296028f8f48b09/6af66/textfield-pattern-require1.png 640w", "/static/7eddbebb880850ace7296028f8f48b09/d9199/textfield-pattern-require1.png 960w", "/static/7eddbebb880850ace7296028f8f48b09/21b4d/textfield-pattern-require1.png 1280w", "/static/7eddbebb880850ace7296028f8f48b09/29007/textfield-pattern-require1.png 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/7eddbebb880850ace7296028f8f48b09/6af66/textfield-pattern-require1.png",
              "alt": "入力必須なTextFieldのパターン一覧",
              "title": "入力必須なTextFieldのパターン一覧",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fdbb2b783ce5eb568d46f51f8b39599e/29007/textfield-pattern-require2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "113.12500000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/fdbb2b783ce5eb568d46f51f8b39599e/2aaaf/textfield-pattern-require2.webp 160w", "/static/fdbb2b783ce5eb568d46f51f8b39599e/85e47/textfield-pattern-require2.webp 320w", "/static/fdbb2b783ce5eb568d46f51f8b39599e/75198/textfield-pattern-require2.webp 640w", "/static/fdbb2b783ce5eb568d46f51f8b39599e/691bc/textfield-pattern-require2.webp 960w", "/static/fdbb2b783ce5eb568d46f51f8b39599e/223e5/textfield-pattern-require2.webp 1280w", "/static/fdbb2b783ce5eb568d46f51f8b39599e/2158a/textfield-pattern-require2.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/fdbb2b783ce5eb568d46f51f8b39599e/69538/textfield-pattern-require2.png 160w", "/static/fdbb2b783ce5eb568d46f51f8b39599e/72799/textfield-pattern-require2.png 320w", "/static/fdbb2b783ce5eb568d46f51f8b39599e/6af66/textfield-pattern-require2.png 640w", "/static/fdbb2b783ce5eb568d46f51f8b39599e/d9199/textfield-pattern-require2.png 960w", "/static/fdbb2b783ce5eb568d46f51f8b39599e/21b4d/textfield-pattern-require2.png 1280w", "/static/fdbb2b783ce5eb568d46f51f8b39599e/29007/textfield-pattern-require2.png 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/fdbb2b783ce5eb568d46f51f8b39599e/6af66/textfield-pattern-require2.png",
              "alt": "必須ラベルの表示パターン",
              "title": "必須ラベルの表示パターン",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h3 {...{
      "id": "非表示パスワード等",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E9%9D%9E%E8%A1%A8%E7%A4%BA%E3%83%91%E3%82%B9%E3%83%AF%E3%83%BC%E3%83%89%E7%AD%89",
        "aria-label": "非表示パスワード等 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`非表示（パスワード等）`}</h3>
    <p>{`パスワード等、セキリュティの観点からデフォルトで非表示状態になっているTextFieldです。`}</p>
    <p>{`ユーザーの誤入力を未然に防ぐため、パスワードのマスキングを任意で解除できるチェックボックスを用意してください。`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0d058f02e4b745a05918b03e4120a96a/29007/textfield-pattern-invisible1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0d058f02e4b745a05918b03e4120a96a/2aaaf/textfield-pattern-invisible1.webp 160w", "/static/0d058f02e4b745a05918b03e4120a96a/85e47/textfield-pattern-invisible1.webp 320w", "/static/0d058f02e4b745a05918b03e4120a96a/75198/textfield-pattern-invisible1.webp 640w", "/static/0d058f02e4b745a05918b03e4120a96a/691bc/textfield-pattern-invisible1.webp 960w", "/static/0d058f02e4b745a05918b03e4120a96a/223e5/textfield-pattern-invisible1.webp 1280w", "/static/0d058f02e4b745a05918b03e4120a96a/2158a/textfield-pattern-invisible1.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0d058f02e4b745a05918b03e4120a96a/69538/textfield-pattern-invisible1.png 160w", "/static/0d058f02e4b745a05918b03e4120a96a/72799/textfield-pattern-invisible1.png 320w", "/static/0d058f02e4b745a05918b03e4120a96a/6af66/textfield-pattern-invisible1.png 640w", "/static/0d058f02e4b745a05918b03e4120a96a/d9199/textfield-pattern-invisible1.png 960w", "/static/0d058f02e4b745a05918b03e4120a96a/21b4d/textfield-pattern-invisible1.png 1280w", "/static/0d058f02e4b745a05918b03e4120a96a/29007/textfield-pattern-invisible1.png 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/0d058f02e4b745a05918b03e4120a96a/6af66/textfield-pattern-invisible1.png",
              "alt": "入力内容の非表示機能がある場合のパターン一覧",
              "title": "入力内容の非表示機能がある場合のパターン一覧",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/10caadac227328c71f8a1d74e85acc6b/29007/textfield-pattern-invisible2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/10caadac227328c71f8a1d74e85acc6b/2aaaf/textfield-pattern-invisible2.webp 160w", "/static/10caadac227328c71f8a1d74e85acc6b/85e47/textfield-pattern-invisible2.webp 320w", "/static/10caadac227328c71f8a1d74e85acc6b/75198/textfield-pattern-invisible2.webp 640w", "/static/10caadac227328c71f8a1d74e85acc6b/691bc/textfield-pattern-invisible2.webp 960w", "/static/10caadac227328c71f8a1d74e85acc6b/223e5/textfield-pattern-invisible2.webp 1280w", "/static/10caadac227328c71f8a1d74e85acc6b/2158a/textfield-pattern-invisible2.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/10caadac227328c71f8a1d74e85acc6b/69538/textfield-pattern-invisible2.png 160w", "/static/10caadac227328c71f8a1d74e85acc6b/72799/textfield-pattern-invisible2.png 320w", "/static/10caadac227328c71f8a1d74e85acc6b/6af66/textfield-pattern-invisible2.png 640w", "/static/10caadac227328c71f8a1d74e85acc6b/d9199/textfield-pattern-invisible2.png 960w", "/static/10caadac227328c71f8a1d74e85acc6b/21b4d/textfield-pattern-invisible2.png 1280w", "/static/10caadac227328c71f8a1d74e85acc6b/29007/textfield-pattern-invisible2.png 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/10caadac227328c71f8a1d74e85acc6b/6af66/textfield-pattern-invisible2.png",
              "alt": "パスワード入力の表示に目のアイコンを使う、確認用入力のフィールドを用意するのを非推奨とする例",
              "title": "パスワード入力の表示に目のアイコンを使う、確認用入力のフィールドを用意するのを非推奨とする例",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h3 {...{
      "id": "リアルタイム判定",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%AA%E3%82%A2%E3%83%AB%E3%82%BF%E3%82%A4%E3%83%A0%E5%88%A4%E5%AE%9A",
        "aria-label": "リアルタイム判定 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`リアルタイム判定`}</h3>
    <p>{`ユーザーが入力中のテキストに対して、確定ボタンを押す前に、その入力内容が使用可能か否かをリアルタイムで判定するサインです。`}</p>
    <p>{`アメーバIDのように重複不可能なものなど、サービスの使用上エラーを誘発しやすい物に対して使用します。`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/19073545e60cc21ee4f46786723b0920/29007/textfield-pattern-validation.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.75000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/19073545e60cc21ee4f46786723b0920/2aaaf/textfield-pattern-validation.webp 160w", "/static/19073545e60cc21ee4f46786723b0920/85e47/textfield-pattern-validation.webp 320w", "/static/19073545e60cc21ee4f46786723b0920/75198/textfield-pattern-validation.webp 640w", "/static/19073545e60cc21ee4f46786723b0920/691bc/textfield-pattern-validation.webp 960w", "/static/19073545e60cc21ee4f46786723b0920/223e5/textfield-pattern-validation.webp 1280w", "/static/19073545e60cc21ee4f46786723b0920/2158a/textfield-pattern-validation.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/19073545e60cc21ee4f46786723b0920/69538/textfield-pattern-validation.png 160w", "/static/19073545e60cc21ee4f46786723b0920/72799/textfield-pattern-validation.png 320w", "/static/19073545e60cc21ee4f46786723b0920/6af66/textfield-pattern-validation.png 640w", "/static/19073545e60cc21ee4f46786723b0920/d9199/textfield-pattern-validation.png 960w", "/static/19073545e60cc21ee4f46786723b0920/21b4d/textfield-pattern-validation.png 1280w", "/static/19073545e60cc21ee4f46786723b0920/29007/textfield-pattern-validation.png 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/19073545e60cc21ee4f46786723b0920/6af66/textfield-pattern-validation.png",
              "alt": "リアルタイム判定がある場合のパターン一覧",
              "title": "リアルタイム判定がある場合のパターン一覧",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h3 {...{
      "id": "テキストエリア-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88%E3%82%A8%E3%83%AA%E3%82%A2-1",
        "aria-label": "テキストエリア 1 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`テキストエリア`}</h3>
    <p>{`プロフィールなどの自由記述が可能な項目に使用するテキストエリアです。`}</p>
    <p>{`ユーザーが文字数をカウントすることが難しいため、標準機能としてテキストカウンターをTextField下部に設けてください。`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2af285202b95c9b27b0926f97971d02a/29007/textfield-pattern-longtext.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.12500000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2af285202b95c9b27b0926f97971d02a/2aaaf/textfield-pattern-longtext.webp 160w", "/static/2af285202b95c9b27b0926f97971d02a/85e47/textfield-pattern-longtext.webp 320w", "/static/2af285202b95c9b27b0926f97971d02a/75198/textfield-pattern-longtext.webp 640w", "/static/2af285202b95c9b27b0926f97971d02a/691bc/textfield-pattern-longtext.webp 960w", "/static/2af285202b95c9b27b0926f97971d02a/223e5/textfield-pattern-longtext.webp 1280w", "/static/2af285202b95c9b27b0926f97971d02a/2158a/textfield-pattern-longtext.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2af285202b95c9b27b0926f97971d02a/69538/textfield-pattern-longtext.png 160w", "/static/2af285202b95c9b27b0926f97971d02a/72799/textfield-pattern-longtext.png 320w", "/static/2af285202b95c9b27b0926f97971d02a/6af66/textfield-pattern-longtext.png 640w", "/static/2af285202b95c9b27b0926f97971d02a/d9199/textfield-pattern-longtext.png 960w", "/static/2af285202b95c9b27b0926f97971d02a/21b4d/textfield-pattern-longtext.png 1280w", "/static/2af285202b95c9b27b0926f97971d02a/29007/textfield-pattern-longtext.png 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2af285202b95c9b27b0926f97971d02a/6af66/textfield-pattern-longtext.png",
              "alt": "テキストエリアのパターン一覧",
              "title": "テキストエリアのパターン一覧",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h3 {...{
      "id": "オートコンプリート-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%AA%E3%83%BC%E3%83%88%E3%82%B3%E3%83%B3%E3%83%97%E3%83%AA%E3%83%BC%E3%83%88-1",
        "aria-label": "オートコンプリート 1 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`オートコンプリート`}</h3>
    <p>{`ユーザーが入力した文字列から、それを補完する形で候補を提示するものです。`}</p>
    <p>{`地理情報など、選択肢に限りがあるがその数が多く、一覧から選択するのが難しい時に使用してください。`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/45b49f4e29ed56c169f26553744e8efe/29007/textfield-pattern-autocomplete1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "88.125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/45b49f4e29ed56c169f26553744e8efe/2aaaf/textfield-pattern-autocomplete1.webp 160w", "/static/45b49f4e29ed56c169f26553744e8efe/85e47/textfield-pattern-autocomplete1.webp 320w", "/static/45b49f4e29ed56c169f26553744e8efe/75198/textfield-pattern-autocomplete1.webp 640w", "/static/45b49f4e29ed56c169f26553744e8efe/691bc/textfield-pattern-autocomplete1.webp 960w", "/static/45b49f4e29ed56c169f26553744e8efe/223e5/textfield-pattern-autocomplete1.webp 1280w", "/static/45b49f4e29ed56c169f26553744e8efe/2158a/textfield-pattern-autocomplete1.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/45b49f4e29ed56c169f26553744e8efe/69538/textfield-pattern-autocomplete1.png 160w", "/static/45b49f4e29ed56c169f26553744e8efe/72799/textfield-pattern-autocomplete1.png 320w", "/static/45b49f4e29ed56c169f26553744e8efe/6af66/textfield-pattern-autocomplete1.png 640w", "/static/45b49f4e29ed56c169f26553744e8efe/d9199/textfield-pattern-autocomplete1.png 960w", "/static/45b49f4e29ed56c169f26553744e8efe/21b4d/textfield-pattern-autocomplete1.png 1280w", "/static/45b49f4e29ed56c169f26553744e8efe/29007/textfield-pattern-autocomplete1.png 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/45b49f4e29ed56c169f26553744e8efe/6af66/textfield-pattern-autocomplete1.png",
              "alt": "オートコンプリートがある場合のパターン一覧",
              "title": "オートコンプリートがある場合のパターン一覧",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4c3f70a29ad62d31e7c516e0047993fd/29007/textfield-pattern-autocomplete2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4c3f70a29ad62d31e7c516e0047993fd/2aaaf/textfield-pattern-autocomplete2.webp 160w", "/static/4c3f70a29ad62d31e7c516e0047993fd/85e47/textfield-pattern-autocomplete2.webp 320w", "/static/4c3f70a29ad62d31e7c516e0047993fd/75198/textfield-pattern-autocomplete2.webp 640w", "/static/4c3f70a29ad62d31e7c516e0047993fd/691bc/textfield-pattern-autocomplete2.webp 960w", "/static/4c3f70a29ad62d31e7c516e0047993fd/223e5/textfield-pattern-autocomplete2.webp 1280w", "/static/4c3f70a29ad62d31e7c516e0047993fd/2158a/textfield-pattern-autocomplete2.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4c3f70a29ad62d31e7c516e0047993fd/69538/textfield-pattern-autocomplete2.png 160w", "/static/4c3f70a29ad62d31e7c516e0047993fd/72799/textfield-pattern-autocomplete2.png 320w", "/static/4c3f70a29ad62d31e7c516e0047993fd/6af66/textfield-pattern-autocomplete2.png 640w", "/static/4c3f70a29ad62d31e7c516e0047993fd/d9199/textfield-pattern-autocomplete2.png 960w", "/static/4c3f70a29ad62d31e7c516e0047993fd/21b4d/textfield-pattern-autocomplete2.png 1280w", "/static/4c3f70a29ad62d31e7c516e0047993fd/29007/textfield-pattern-autocomplete2.png 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/4c3f70a29ad62d31e7c516e0047993fd/6af66/textfield-pattern-autocomplete2.png",
              "alt": "選択肢によってはオートコンプリートを避けるパターン",
              "title": "選択肢によってはオートコンプリートを避けるパターン",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h2 {...{
      "id": "振る舞い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%8C%AF%E3%82%8B%E8%88%9E%E3%81%84",
        "aria-label": "振る舞い permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`振る舞い`}</h2>
    <p>{`TextFieldは、システムの要件によって、どのタイミングでユーザーの入力に対してレスポンスをするかが変わります。`}</p>
    <h3 {...{
      "id": "次へでバリデーション",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%AC%A1%E3%81%B8%E3%81%A7%E3%83%90%E3%83%AA%E3%83%87%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3",
        "aria-label": "次へでバリデーション permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`「次へ」でバリデーション`}</h3>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1dc152601d08a53762e5ed999590ed59/29007/textfield-behavior-validation.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1dc152601d08a53762e5ed999590ed59/2aaaf/textfield-behavior-validation.webp 160w", "/static/1dc152601d08a53762e5ed999590ed59/85e47/textfield-behavior-validation.webp 320w", "/static/1dc152601d08a53762e5ed999590ed59/75198/textfield-behavior-validation.webp 640w", "/static/1dc152601d08a53762e5ed999590ed59/691bc/textfield-behavior-validation.webp 960w", "/static/1dc152601d08a53762e5ed999590ed59/223e5/textfield-behavior-validation.webp 1280w", "/static/1dc152601d08a53762e5ed999590ed59/2158a/textfield-behavior-validation.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/1dc152601d08a53762e5ed999590ed59/69538/textfield-behavior-validation.png 160w", "/static/1dc152601d08a53762e5ed999590ed59/72799/textfield-behavior-validation.png 320w", "/static/1dc152601d08a53762e5ed999590ed59/6af66/textfield-behavior-validation.png 640w", "/static/1dc152601d08a53762e5ed999590ed59/d9199/textfield-behavior-validation.png 960w", "/static/1dc152601d08a53762e5ed999590ed59/21b4d/textfield-behavior-validation.png 1280w", "/static/1dc152601d08a53762e5ed999590ed59/29007/textfield-behavior-validation.png 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/1dc152601d08a53762e5ed999590ed59/6af66/textfield-behavior-validation.png",
              "alt": "「次へ」の操作でバリデーションする画面遷移",
              "title": "「次へ」の操作でバリデーションする画面遷移",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h3 {...{
      "id": "次へでバリデーションリアルタイムで文字数バリデーション",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%AC%A1%E3%81%B8%E3%81%A7%E3%83%90%E3%83%AA%E3%83%87%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E3%83%AA%E3%82%A2%E3%83%AB%E3%82%BF%E3%82%A4%E3%83%A0%E3%81%A7%E6%96%87%E5%AD%97%E6%95%B0%E3%83%90%E3%83%AA%E3%83%87%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3",
        "aria-label": "次へでバリデーションリアルタイムで文字数バリデーション permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`「次へ」でバリデーション＋リアルタイムで文字数バリデーション`}</h3>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2de542081d538fc8d689320748a679ec/29007/textfield-behavior-validation-realtime.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2de542081d538fc8d689320748a679ec/2aaaf/textfield-behavior-validation-realtime.webp 160w", "/static/2de542081d538fc8d689320748a679ec/85e47/textfield-behavior-validation-realtime.webp 320w", "/static/2de542081d538fc8d689320748a679ec/75198/textfield-behavior-validation-realtime.webp 640w", "/static/2de542081d538fc8d689320748a679ec/691bc/textfield-behavior-validation-realtime.webp 960w", "/static/2de542081d538fc8d689320748a679ec/223e5/textfield-behavior-validation-realtime.webp 1280w", "/static/2de542081d538fc8d689320748a679ec/2158a/textfield-behavior-validation-realtime.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2de542081d538fc8d689320748a679ec/69538/textfield-behavior-validation-realtime.png 160w", "/static/2de542081d538fc8d689320748a679ec/72799/textfield-behavior-validation-realtime.png 320w", "/static/2de542081d538fc8d689320748a679ec/6af66/textfield-behavior-validation-realtime.png 640w", "/static/2de542081d538fc8d689320748a679ec/d9199/textfield-behavior-validation-realtime.png 960w", "/static/2de542081d538fc8d689320748a679ec/21b4d/textfield-behavior-validation-realtime.png 1280w", "/static/2de542081d538fc8d689320748a679ec/29007/textfield-behavior-validation-realtime.png 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2de542081d538fc8d689320748a679ec/6af66/textfield-behavior-validation-realtime.png",
              "alt": "「次へ」の操作でのバリデーションと、文字数カウントのバリデーションが共存する画面遷移",
              "title": "「次へ」の操作でのバリデーションと、文字数カウントのバリデーションが共存する画面遷移",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h2 {...{
      "id": "アクセシビリティ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B7%E3%83%93%E3%83%AA%E3%83%86%E3%82%A3",
        "aria-label": "アクセシビリティ permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`アクセシビリティ`}</h2>
    <h3 {...{
      "id": "操作",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%93%8D%E4%BD%9C",
        "aria-label": "操作 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`操作`}</h3>
    <details>
      <summary>制限時間を作らない</summary>
      <p>{`入力や操作に時間のかかるユーザーもいます。また、単純に気づかずに見逃してしまうこともあるかもしれません。入力に制限時間を設けないことはもちろん、一時的に表示するようなエラーテキストなどの表示も、時間が経ったからといって隠してはいけません。`}</p>
      <p>{`参考: `}<a parentName="p" {...{
          "href": "https://openameba.github.io/a11y-guidelines/2/2/1/"
        }}>{`2.2.1 コンテンツに制限時間を設けない- Ameba Accessibility Guidelines`}</a></p>
    </details>
    <details>
      <summary>入力時に予測できない変化を起こさない</summary>
      <p>{`「1文字入力すると自動で次の入力欄にフォーカスが移動してしまう」など、入力時に予測できない変化を起こしてはいけません。
特に全盲の人や特定の認知能力が低下している人は、その変化に気づかず入力エリアを間違えたり、気を取られて操作が困難になってしまったりする可能性があります。`}</p>
      <p>{`参考: `}<a parentName="p" {...{
          "href": "https://openameba.github.io/a11y-guidelines/3/2/2/"
        }}>{`3.2.2 入力時に予測できない変化を起こさない - Ameba Accessibility Guidelines`}</a></p>
    </details>
    <details>
      <summary>エラーの箇所、内容を特定できる</summary>
      <p>{`入力エラーが機械的に検出できる場合は、エラーとなっている箇所とエラー内容が特的できるように表示します。詳細なエラーを伝えることが難しい場合、ヘルプページへのリンクなどを用意し、エラーが解決できるよう補助します。`}</p>
      <p>{`参考: `}<a parentName="p" {...{
          "href": "https://openameba.github.io/a11y-guidelines/3/3/1/"
        }}>{`3.3.1 エラーを特定できる - Ameba Accessibility Guidelines`}</a></p>
    </details>
    <h3 {...{
      "id": "色とコントラスト",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E8%89%B2%E3%81%A8%E3%82%B3%E3%83%B3%E3%83%88%E3%83%A9%E3%82%B9%E3%83%88",
        "aria-label": "色とコントラスト permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`色とコントラスト`}</h3>
    <details>
      <summary>色だけで説明しない</summary>
      <p>{`色だけで情報や状態を表現すると、ユーザーや環境によっては状態の違いがわからなくなってしまいます。色以外の、テキストやborder、weightの太さで変化をつけるようにしましょう。`}</p>
      <p>{`例:「赤文字は必須項目です」表記、エラーのある入力エリアを赤いborderで表現する。`}</p>
      <p>{`参考: `}<a parentName="p" {...{
          "href": "https://openameba.github.io/a11y-guidelines/1/4/1/"
        }}>{`1.4.1 色だけで伝えない - Ameba Accessibility Guidelines`}</a></p>
    </details>
    <details>
      <summary>テキスト色、アイコン色と背景色のコントラスト比を高くする</summary>
      <p>{`テキスト、アイコンの視認性を高めるため、背景色に対してそれぞれ下記のコントラスト比を担保しましょう。`}</p>
      <ul>
        <li parentName="ul">{`テキスト、画像中のテキストは4.5:1以上`}</li>
        <li parentName="ul">{`アイコン色は3:1以上`}</li>
      </ul>
      <p>{`SpindleのSurface ColorとText Color・Object Colorの組み合わせを適切に利用することで、コントラスト比が十分に担保されます。`}</p>
      <p>{`参考: `}<a parentName="p" {...{
          "href": "https://openameba.github.io/a11y-guidelines/1/4/3/"
        }}>{`1.4.3 テキストや文字画像のコントラストを確保する - Ameba Accessibility Guidelines`}</a></p>
    </details>
    <h3 {...{
      "id": "ライティング",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%A9%E3%82%A4%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0",
        "aria-label": "ライティング permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`ライティング`}</h3>
    <details>
      <summary>入力エリアに見出しか説明をつける</summary>
      <p>{`ユーザーが何を入力すれば良いのかを、見出しか説明文で明らかにしてください。また、実装で入力エリアと紐づけておくことで、支援技術を使って閲覧しているユーザーにも関係性がわかるようになります。`}</p>
      <p>{`プレースホルダーはあくまで入力例を補足的に示すために用い、見出しの代わりとして使ってはいけません。入力中に内容がわからなくなったり、視認性が極端に低いため支援技術で認識できないことがあったりするためです。`}</p>
      <p>{`参考: `}<a parentName="p" {...{
          "href": "https://openameba.github.io/a11y-guidelines/3/3/2/"
        }}>{`3.3.2 入力項目にラベルまたは説明をつける - Ameba Accessibility Guidelines`}</a></p>
    </details>
    <h3 {...{
      "id": "レイアウト",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%AC%E3%82%A4%E3%82%A2%E3%82%A6%E3%83%88",
        "aria-label": "レイアウト permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`レイアウト`}</h3>
    <details>
      <summary>テキスト・アイコンが拡大縮小できる</summary>
      <p>{`テキストやアイコンサイズは、200％まで拡大できるようにします。`}</p>
      <p>{`ロービジョンのユーザーは、小さなテキストを読むことが困難です。そのため、テキストサイズを大きく設定していたり、一時的に画面をズームして表示したりすることがあります。拡大縮小した際に、見出しやエラーテキストなどの要素が見切れて表示され、読めないことのないように設計しましょう。また、拡大してもアイコンだけが小さいままだと、アイコンを見逃してしまうかもしれません。`}</p>
      <p>{`なお、一部のOSでは入力エリアのフォントサイズが16px相当以下の場合に、入力時にズームして表示することがあります。`}</p>
      <p>{`参考: `}<a parentName="p" {...{
          "href": "https://openameba.github.io/a11y-guidelines/1/4/4/"
        }}>{`1.4.4 テキストサイズを拡大縮小できる - Ameba Accessibility Guidelines`}</a></p>
    </details>
    <h3 {...{
      "id": "実装",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%AE%9F%E8%A3%85",
        "aria-label": "実装 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`実装`}</h3>
    <details>
      <summary>プラットフォーム標準の方法で実装している or 支援技術で操作ができる</summary>
      <p>{`プラットフォームごとに標準の要素でボタンを実装します。標準的な実装であれば、キーボードやスクリーンリーダーなどの支援技術でも操作できます。`}</p>
      <p>{`もしカスタムした入力エリアを使う場合には、エミュレータでキー操作ができること、支援技術（VoiceOver, Talkbackなど）で動作を確認しましょう。また、入力できない（disabledな）入力エリアが、キー操作・支援技術でも操作できなくなることを確認しましょう。`}</p>
      <p>{`参考: `}<a parentName="p" {...{
          "href": "https://openameba.github.io/a11y-guidelines/2/1/1/"
        }}>{`2.1.1 キーボード、タッチデバイスで操作できる - Ameba Accessibility Guidelines`}</a></p>
    </details>
    <details>
      <summary>アイコンには代替テキストを設定する</summary>
      <p>{`トレーリングアイコンなどのアイコンにテキストラベルがないと、支援技術にはステータスがわからなくなってしまうことがあります。アイコンだけの表示には、代替テキストを設定しましょう。`}</p>
      <p>{`参考: `}<a parentName="p" {...{
          "href": "https://openameba.github.io/a11y-guidelines/1/1/1/"
        }}>{`1.1.1 画像に代替テキストを提供する - Ameba Accessibility Guidelines`}</a></p>
    </details>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      